import styled from "@emotion/native";
import { ViewStyle, Platform } from "react-native";
import { scale } from "myplan/utils/scaling";
import { CONTAINER_WIDTH } from "myplan/components/DrawerButton/styles";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const Container = styled.View(
	{
		flex: 1,
		paddingLeft: 25,
	},
	({ theme }: any) => {
		return {
			backgroundColor: theme.color.background,
		};
	}
);

export const Body = styled.ScrollView({
	flex: 1,
	overflow: Platform.OS === ("android" || "web") ? "scroll" : "visible",
});

Body.defaultProps = {
	contentContainerStyle: { flex: 1 },
};

export const CloseButton = styled.TouchableOpacity(
	{
		position: "absolute",
		width: CONTAINER_WIDTH,
		height: CONTAINER_WIDTH,
		right: 10,
		borderRadius: CONTAINER_WIDTH / 2,
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1,
	},
	({ theme }: any) => {
		const insets = useSafeAreaInsets();
		const top = insets.top + 4;

		let style: ViewStyle = {
			top,
			backgroundColor: theme.color.accent,
		};

		if (theme.verticalScale < 1) {
			const width = CONTAINER_WIDTH * theme.verticalScale;
			const height = width;

			style = {
				...style,
				width,
				height,
			};
		}

		return style;
	}
);

export const Main = styled.View({
	paddingTop: scale(70),
});

export const NavigationItem = styled.TouchableOpacity({
	marginBottom: scale(32),
	display: "flex",
	flexDirection: "row",
});

export const Footer = styled.View({
	justifyContent: "center",
	paddingBottom: 27,
});
